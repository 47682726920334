import logo from './logo.svg';
import React, { useContext, useEffect, useState} from 'react'

import './App.css';

function App() {
  const d = new Date();
  const [isSummer, setIsSommer] = useState();
  const [runonce, setrunonce] = useState();
  
  useEffect(() => {
    document.title = 'Er sumarið komið?';
  const dates=[{"type":"S", "date": new Date('2024-04-25')},
  {"type":"W", "date": new Date('2024-10-26')},
  {"type":"S", "date": new Date('2025-04-24')},
  {"type":"W", "date": new Date('2025-10-25')},
  {"type":"S", "date": new Date('2026-04-23')},
  {"type":"W", "date": new Date('2026-10-24')},
  {"type":"S", "date": new Date('2027-04-22')},
  {"type":"W", "date": new Date('2027-10-23')},
  {"type":"S", "date": new Date('2028-04-20')},
  {"type":"W", "date": new Date('2028-10-21')}]

  for(let i=0;i<dates.length;i++)
  {
    if(d<dates[i].date){
         if (dates[i-1].type==="S")  setIsSommer(true);  
    return;}
  }

}, [runonce]);

  
  return (
    <div className="App">
      <header className="App-header">
       
        <p>Er sumar ?
        </p>
        {isSummer ? <div>jább</div>: <div>Nei</div>}
        <br/>

        

      </header>
    </div>
  );
}

export default App;
